export const environment = {
    e2e: false,
    production: false,
    firebase: {
        apiKey: "AIzaSyDr_ZetxwbvR0JgSGA0DVkvTdBjfRX8Tsc",
        authDomain: "deloitte.ai"
    },
    region: "RemoveMe",
    brain_base_url: "https://ergo.api.deloitte.camp",
    breadcrumbs: {
        assetOwner: {
            aoh: "Asset Owner Hub",
            assetManagement: "Asset Management",
            principleAssetQuestions: "Principle asset questions",
            offerRequestDetail: "Offer request detail",
            assetPreview: "Preview",
        },
        store: {
            deloitteAiCatalog: "Deloitte.Ai",
            checkout: "Checkout",
            standingOrderDetail: "Standing order detail",
            storefront: "Storefront",
            orderHistory: "Order History",
            myEntitlements: "My Entitlements"
        }
    },
    aoh_url: "https://aoh.ergo.cwl.camp",
    store_url: "https://ergo.cwl.camp",
    api_base_url: "https://ergo.api.deloitte.camp",
    authParams: ["oidc.deloitte"],
    signInType: 'signInWithPopup',
    hasEnterpriseAssetSearch: false,
    showBarrierQuestions: false,
    asset_redirect_uri: {},
    onboarding: {
        displayVideo: false,
        video_url: "https://storage.googleapis.com/dcsp-catalog-assets-/data/products/Deloitte%20AI%20Video/Deloitte%20AI%20-%20Asset%20Owner%20Hub.mp4",
        development: {
            frontend_url: "https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Frontend_Development_Kit/Deloitte.AI%20Frontend%20Development%20Kit.pdf",
            backend_url: "https://bitbucket.de.deloitte.com/projects/AI/repos/dcsp-python-framework/browse",
            business_support_email: "mailto:GlobalDeloitteAi@deloitte.com?subject=Question%20regarding%20Deloitte%20AI%20Marketplace",
        }
    },
    barrierQuestionsAbort: {
        mailAddress: "mahorst@deloitte.de"
    },
    support: {
        ccEmail: "",
        contact: "mailto:GlobalDeloitteAi@deloitte.com?subject=Question%20regarding%20Deloitte%20AI%20Marketplace",
    },
    onboarding_options: {
        "manual": {},
        "api_passthrough": {},
        "gen_ai": {
            api_url: "https://ergo-geniusai.connect.api.deloitte.ai",
            app_url: "https://geniusai.ergo.cwl.camp/workflow-selector"
        }
    }
};
